import { useCallback, useMemo } from 'react';
import useQuery from '@/react-query/common/hooks/useQuery';
import { QK_STORIES } from '@/config/query-keys';
import { formatChannel } from '@/helpers/common';
import getStories from '@/helpers/api/discover/getStories';
import { truthyOrZero } from '@/helpers/math';

const useStories = ({
  enabled = true, staleTime, ...otherParams
} = {}) => {
  const fetchStories = useCallback(() => getStories(), []);

  const results = useQuery({
    ...otherParams,
    queryKey: [QK_STORIES],
    queryFn: fetchStories,
    enabled,
    staleTime: truthyOrZero(staleTime) ? staleTime : (1000 * 60 * 5) // 5 minutes
  });

  const { data } = results;

  const {
    json,
    notFound,
    status: statusCode
  } = data || {};

  const {
    description, stories, title
  } = json || {};

  const titleFormatted = (title || '').trim();
  const descriptionFormatted = (description || '').trim();
  const storiesFormatted = useMemo(
    () => (stories || [])
      .map((story) => ({
        ...story,
        show: formatChannel(story.show)
      })),
    [stories]
  );

  return {
    ...results,
    notFound,
    statusCode,
    stories: storiesFormatted,
    description: descriptionFormatted,
    title: titleFormatted
  };
};

export default useStories;