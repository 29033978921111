import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { streamingContextTypes } from '@/config/events-keakie';
import ConnectedShowPreviewRow from '@/components/app/ConnectedShowPreviewRow';
import useAudioReduxControlsCustom from '@/hooks/audio/useAudioReduxControlsCustom';
import useMainScrollElement from '@/hooks/layout/useMainScrollElement';
import useDiscoverRow from '@/react-query/discover/hooks/useDiscoverRow';
import sendChannelViewEvent from '@/helpers/api/events/sendChannelViewEvent';

const ConnectedDiscoverRow = ({
  id,
  isLoading
}) => {
  const [inView, setInView] = useState(false);

  const { ref: mainScrollElementRef } = useMainScrollElement({ notifyRefAttached: true });

  const handleSetInView = useCallback(() => setInView(true), []);

  const {
    row,
    error: showsError,
    notFound: showsNotFound,
    showsArray,
    isLoading: showsLoading
  } = useDiscoverRow({
    id,
    enabled: inView
  });

  const recommendationType = row.recommendation_type;
  const recommendationId = row.recommendation_id;

  const { handlePlayEpisodeFromShow } = useAudioReduxControlsCustom({ streamingContext: streamingContextTypes.DISCOVER_ROW_SHOW_SLATE });

  const onClickChannel = useCallback(({ id: passedId } = {}) => {
    sendChannelViewEvent({
      channelId: passedId,
      recommendationId,
      recommendationType
    });
  }, [recommendationType, recommendationId]);

  // ------------------- LOADING/ERROR STATE -------------------
  const isLoadingState = isLoading;
  const isLoadingShowsState = showsLoading || isLoading;

  return !showsNotFound && (
    <ConnectedShowPreviewRow
      channelPreviews={ showsArray }
      description={ row.description }
      error={ showsError }
      id={ id }
      intersectionRoot={ mainScrollElementRef.current }
      isExpandable={ row.is_expandable }
      isLoading={ isLoadingState }
      isLoadingShows={ isLoadingShowsState }
      onClickChannel={ onClickChannel }
      onClickPlay={ handlePlayEpisodeFromShow }
      onInView={ handleSetInView }
      recommendationId={ row.recommendation_id }
      recommendationSlug={ row.recommendation_slug }
      recommendationType={ row.recommendation_type }
      title={ row.row_title }
    />
  );
};

ConnectedDiscoverRow.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool
};

ConnectedDiscoverRow.defaultProps = {
  id: null,
  isLoading: false
};

// ------ Connect with the store and inject required state as props ------
export default ConnectedDiscoverRow;