import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';
import Text, { FONT_SIZE_110, FONT_SIZE_080 } from '@/components/component-library/Text';
import GenreBadges from '@/components/component-library/GenreBadges';

const DescriptionLoading = () => (
  <>
    <Text className={ styles.description } loading size={ FONT_SIZE_080 } />
    <Text className={ styles.descriptionLoadingLastLine } loading size={ FONT_SIZE_080 } />
  </>
);

const Details = ({
  title,
  description,
  genres,
  loading,
  displayGenres,
  displayTitle
}) => {
  return (
    <div className={ styles.slateInfo }>
      { displayTitle && (
        <Text
          bold
          className={ classes(styles.title, { [styles.titleLoading]: loading }) }
          fixed
          loading={ loading }
          size={ FONT_SIZE_110 } useHeaderFont
        >
          { title }
        </Text>
      ) }
      { displayGenres && <GenreBadges enableLinks genres={ genres } loading={ loading } /> }
      { loading
        ? <DescriptionLoading />
        : (
          <Text
            bold
            className={ styles.description }
            maximumLineCount={ 3 }
            size={ FONT_SIZE_080 } useHeaderFont
          >
            { description }
          </Text>
        ) }
    </div>
  );
};

Details.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  genres: PropTypes.array,
  loading: PropTypes.bool,
  displayGenres: PropTypes.bool,
  displayTitle: PropTypes.bool
};

Details.defaultProps = {
  title: '',
  description: '',
  genres: [],
  loading: false,
  displayGenres: false,
  displayTitle: false
};

export default Details;