import { useQueryClient } from '@tanstack/react-query';
import {
  useCallback, useEffect, useMemo
} from 'react';
import keyBy from 'lodash.keyby';
import useQuery from '@/react-query/common/hooks/useQuery';
import getDiscoverInfo from '@/helpers/api/discover/getDiscover';
import { QK_DISCOVER, QK_DISCOVER_ROW_SHOWS } from '@/config/query-keys';

const DISCOVER_CALL_PARAMS = {
  offset: 0,
  l: 15
};

const useDiscoverData = ({ enabled = false } = {}) => {
  const fetchDiscover = useCallback(() => getDiscoverInfo({ params: DISCOVER_CALL_PARAMS }), []);

  const queryClient = useQueryClient();
  const results = useQuery({
    queryKey: [QK_DISCOVER, DISCOVER_CALL_PARAMS],
    queryFn: fetchDiscover,
    enabled,
    staleTime: 1000 * 60 * 30 // 30 minutes
  });

  const {
    isLoading,
    data
  } = results;

  useEffect(() => {
    if (isLoading) {
      queryClient.invalidateQueries([QK_DISCOVER_ROW_SHOWS]);
    }
  }, [isLoading, queryClient]);

  const {
    json,
    notFound,
    status: statusCode
  } = data || {};
  const { nodes } = json || {};

  const rowsArray = useMemo(() => (nodes || []).map((row) => {
    const rt = row.recommendation_type;
    const rid = row.recommendation_id;
    const ep = row.endpoint;
    const rowId = (rt && rid)
      ? `${ rt }-${ rid }-${ ep }`
      : row.row_title;
    
    return {
      ...row,
      id: rowId
    };
  }), [nodes]);
  const rowIds = useMemo(() => rowsArray.map(({ id }) => id), [rowsArray]);
  const heroRow = useMemo(() => rowsArray.find((row) => row.object_type === 'hero'), [rowsArray]);
  const heroRowId = heroRow ? heroRow.id : null;
  const rowsObject = useMemo(() => keyBy(rowsArray, 'id'), [rowsArray]);

  return {
    ...results,
    notFound,
    statusCode,
    rowsArray,
    rowIds,
    rowsObject,
    heroRowId
  };
};

export default useDiscoverData;