import {
  useEffect, useState, useCallback, useMemo
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Provider } from './context';
import { GApageViewDiscover } from '@/helpers/events-ga/page-views';
import { ICpageViewDiscover } from '@/helpers/events-intercom/page-views';
import useSendGAEvent from '@/hooks/ga/useSendGAEvent';
import useSendIntercomEvent from '@/hooks/intercom/useSendIntercomEvent';
import useDiscoverData from '@/react-query/discover/hooks/useDiscoverData';
import { QK_DISCOVER } from '@/config/query-keys';

const DiscoverPageProvider = ({ children }) => {
  const queryClient = useQueryClient();

  // Stop the discover page refetching whilst on the page.
  // It should only refetch when revisiting the page
  const [fetchedForPageVisit, setFetchedForPageVisit] = useState(false);

  // Fetch discover data
  const { isSuccess } = useDiscoverData({ enabled: !fetchedForPageVisit });

  // Set the data as fetched
  useEffect(() => {
    if (isSuccess && !fetchedForPageVisit) {
      setFetchedForPageVisit(true);
    }
  }, [isSuccess, fetchedForPageVisit]);

  // Page view events
  useSendGAEvent({ createEvent: GApageViewDiscover });
  useSendIntercomEvent({ createEvent: ICpageViewDiscover });

  // Handle resetting the discover state
  const handleClickRetry = useCallback(() => queryClient.invalidateQueries([QK_DISCOVER]), [queryClient]);

  const { isError, notFound } = useDiscoverData();

  const value = useMemo(() => ({
    handleClickRetry,
    isError,
    notFound
  }), [handleClickRetry, isError, notFound]);

  return (
    <Provider value={ value }>
      { children }
    </Provider>
  );
};

DiscoverPageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
    
export default DiscoverPageProvider;