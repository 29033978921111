import { useCallback, useMemo } from 'react';
import { channelRecommendationTypes, streamingContextTypes } from '@/config/events-keakie';
import sendChannelViewEvent from '@/helpers/api/events/sendChannelViewEvent';
import { sliceGenres } from '@/helpers/common';
import useStories from '@/react-query/discover/hooks/useStories';
import pageKeys from '@/helpers/routing/constants';
import { createImageAltTextForShow } from '@/helpers/seo/shows';
import useShowEdgePlayback from '@/hooks/audio/useShowEdgePlayback';
import useGA from '@/hooks/ga/useGA';
import { GAclickedStory, GAplayedStory } from '@/helpers/events-ga/feature-engagement';
import useRandomStory from '@/react-query/discover/hooks/useRandomStory';

const useStory = ({
  id,
  enabled = true,
  useRandomStoryHook = false
} = {}) => {
  const { sendGAEvent } = useGA();
  const resultsStories = useStories({ enabled: enabled && !useRandomStoryHook });
  const resultsRandomStory = useRandomStory({ enabled: enabled && useRandomStoryHook });
  const results = useRandomStoryHook ? resultsRandomStory : resultsStories;
  const {
    stories,
    isLoading: isLoadingStories,
    isFetching: isFetchingStories
  } = resultsStories;
  const {
    story: randomStory,
    isLoading: isLoadingRandomStory,
    isFetcing: isFetchingRandomStory
  } = resultsRandomStory;
  const isLoading = useRandomStoryHook ? isLoadingRandomStory : isLoadingStories;
  const isFetching = useRandomStoryHook ? isFetchingRandomStory : isFetchingStories;
  const story = useMemo(() => (useRandomStoryHook ? randomStory : stories.find(({ show: { id: showId } }) => showId === id)), [id, stories, randomStory, useRandomStoryHook]);
  const { show, description: storyDescription } = story || {};

  const {
    id: showId,
    slug,
    title: showTitle,
    genres,
    description,
    assets,
    story: showStoryDescription
  } = show || {};

  const {
    handleClickPlay,
    seasonNumberToPlay,
    episodeNumberToPlay,
    paused,
    isCurrentlyPlayingShow,
    isLoading: isLoadingEdge
  } = useShowEdgePlayback({
    showId,
    streamingContext: streamingContextTypes.DISCOVER_HERO_PLAY_BUTTON
  });

  const playButtonIsLoading = isLoadingEdge || isLoading || isFetching;

  const slicedGenres = useMemo(() => sliceGenres({
    genres,
    limit: 2
  }), [genres]);

  const showHref = pageKeys.shows(slug);

  const handleClickStory = useCallback(() => {
    sendGAEvent(GAclickedStory({
      showId,
      showTitle
    }));

    sendChannelViewEvent({
      channelId: showId,
      recommendationType: channelRecommendationTypes.HERO,
      recommendationId: null
    }).catch(_ => _);
  }, [showId, showTitle, sendGAEvent]);

  const handleClickPlayStory = useCallback((e) => {
    e.preventDefault(); // Avoid the play button triggering anchor hrefs
    e.stopPropagation();

    // Only send the event if the user specifically plays a show because it was a story
    if (paused || !isCurrentlyPlayingShow) {
      sendGAEvent(GAplayedStory({
        showId,
        showTitle
      }));
    }

    handleClickPlay();
  }, [paused, handleClickPlay, showId, showTitle, sendGAEvent, isCurrentlyPlayingShow]);

  return {
    ...results,
    isLoading: isLoading || !showId,
    isFetching: isFetching || !showId,
    playButtonIsLoading,
    showId,
    description,
    show,
    storyDescription: storyDescription || showStoryDescription,
    episodeNumberToPlay,
    genres: slicedGenres,
    hidePlayButton: !!showId && !playButtonIsLoading && !handleClickPlay,
    hideShareButton: true,
    isCurrentlyPlayingShow,
    isPaused: paused,
    handleClickStory: showId ? handleClickStory : undefined,
    showHref,
    handleClickPlayStory: handleClickPlay ? handleClickPlayStory : undefined,
    seasonNumberToPlay,
    title: showTitle,
    assets,
    altText: createImageAltTextForShow({ show })
  };
};

export default useStory;