import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const ButtonWrapper = ({
  children,
  className,
  disableHoverEffect,
  ...props
}) => (
  <button
    { ...props }
    className={ classes(className, styles.default, { [styles.includeHoverEffect]: !disableHoverEffect }) }
  >
    { children }
  </button>
);

ButtonWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  disableHoverEffect: PropTypes.bool
};

ButtonWrapper.defaultProps = {
  className: '',
  disableHoverEffect: false
};

export default ButtonWrapper;