import styles from './styles.module.scss';
import Stories from './Stories';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import Text, { FONT_SIZE_090, FONT_SIZE_060 } from '@/components/component-library/Text';
import Gradient, { GRADIENT_POSITION_RIGHT } from '@/components/app/Gradient';
import useStories from '@/react-query/discover/hooks/useStories';

const DiscoverSectionStories = () => {
  const {
    title,
    description,
    isFetching
  } = useStories({ isEnabled: true });

  return (
    <>
      <LayoutWrapperHorizontal className={ styles.titleSection }>
        <Text
          bold
          className={ styles.titleText }
          size={ FONT_SIZE_090 }
          useHeaderFont
        >
          { title ?? 'Stories' }
        </Text>
        <Text
          bold
          className={ styles.recommendationText }
          size={ FONT_SIZE_060 }
          useHeaderFont
        >
          { description ?? 'Description of Stories' }
        </Text>
      </LayoutWrapperHorizontal>
      <div className={ styles.storiesWrapper }>
        <Stories />
        <Gradient
          breadth='100%'
          directionReverse
          gradientBreadth='10%'
          position={ GRADIENT_POSITION_RIGHT }
        />
      </div>
    </>
  );
};

export default DiscoverSectionStories;