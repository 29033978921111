import HeroStories from './HeroStories';
import styles from './styles.module.scss';
import DiscoverRows from '@/components/app/DiscoverRows';
import LayoutWrapperVertical from '@/components/component-library/LayoutWrapperVertical';
import HeroShiftedPageContent from '@/components/app/ShowHeroDesktop/HeroShiftedPageContent';

const DiscoverPageDesktop = () => {
  return (
    <div className='dskt__discover-page'>
      <HeroStories />
      <HeroShiftedPageContent>
        <LayoutWrapperVertical
          className={ styles.contentWrapper }
          disableTopOnDesktop
          extraPagePadding
        >
          <DiscoverRows />
        </LayoutWrapperVertical>
      </HeroShiftedPageContent>
    </div>
  );
};

export default DiscoverPageDesktop;