import {
  useEffect, useState, useRef
} from 'react';

const useDelayedMount = ({ shouldMount = false, delayTime = 250 }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [transitionComplete, setTransitionComplete] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    if (shouldMount && !shouldRender) {
      setShouldRender(true);
    } else if (shouldMount && shouldRender && !transitionComplete) {
      setTimeout(
        () => setTransitionComplete(true),
        0
      );
    }
  }, [shouldMount, shouldRender, transitionComplete]);

  useEffect(() => {
    if (!shouldMount) {
      setTransitionComplete(false);

      timeoutRef.current = setTimeout(
        () => setShouldRender(false),
        delayTime
      );
    }

    return () => clearTimeout(timeoutRef.current);
  }, [shouldMount, delayTime, shouldRender, timeoutRef]);

  return {
    shouldRender,
    transitionComplete
  };
};

export default useDelayedMount;