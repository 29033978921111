import DiscoverMobileHeader from './DiscoverMobileHeader';
import DiscoverSectionStories from './DiscoverSectionStories';
import DiscoverRows from '@/components/app/DiscoverRows';
import LayoutWrapperVertical from '@/components/component-library/LayoutWrapperVertical';

const DiscoverPageMobile = () => {
  return (
    <div className='mob__discover-page'>
      <DiscoverMobileHeader />
      <LayoutWrapperVertical>
        <DiscoverSectionStories />
        <DiscoverRows />
      </LayoutWrapperVertical>
    </div>
  );
};

export default DiscoverPageMobile;