import { callApi } from '@/helpers/api';
import CONFIG from '@/config/global';

const {
  api: {
    HOST, GET_DISCOVER, GET_DISCOVER_GENRE
  }
} = CONFIG;

// Get the discover information for the user
// type: 'discover' will fetch algorithmic channel rows for the user (homepage)
const getDiscover = ({
  params, type = 'discover', id
}) => {
  // If genre, we need to insert the genre slug in the url
  if (type === 'genre') {
    return callApi({
      url: HOST(GET_DISCOVER_GENRE) + GET_DISCOVER_GENRE.replace(':genre_slug', id),
      queryParams: params
    });
  }

  // If discover, call the generic discovery endpoint
  return callApi({
    url: HOST(GET_DISCOVER) + GET_DISCOVER,
    queryParams: params
  });
};

export default getDiscover;