import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const Div = ({ ...props }) => (<div { ...props } />);

const CarouselIndicators = ({
  ids,
  selectedId,
  onSelectId,
  Component,
  className,
  indicatorClassName,
  getIndicatorProps
}) => {
  const IndicatorComponent = Component || Div;

  return (
    <div className={ classes(styles.carouselIndicators, className) }>
      { ids.map((id) => (
        <IndicatorComponent
          className={ classes(styles.carouselIndicator, indicatorClassName, { [styles.isSelected]: selectedId === id }) }
          onClick={ () => onSelectId(id) }
          { ...(getIndicatorProps ? getIndicatorProps(id) : {}) }
        />
      )) }
    </div>
  );
};

CarouselIndicators.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedId: PropTypes.string.isRequired,
  onSelectId: PropTypes.func.isRequired,
  getIndicatorProps: PropTypes.func,
  Component: PropTypes.elementType,
  className: PropTypes.string,
  indicatorClassName: PropTypes.string
};

CarouselIndicators.defaultProps = {
  getIndicatorProps: _ => _,
  className: '',
  indicatorClassName: '',
  Component: undefined
};

CarouselIndicators.defaultProps = {};

export default CarouselIndicators;