import React, { useCallback, useEffect, useState } from 'react';
import Tip from './Tip';
import styles from './styles.module.scss';
import { getLocalStorageItem, setLocalStorageItem } from '@/helpers/localStorage';
import LocationJourneyIcon from '@/components/component-library/Icon/types/LocationJourney';
import ShowsIcon from '@/components/component-library/Icon/types/Shows';
import HandpickedIcon from '@/components/component-library/Icon/types/Handpicked';
import Modal from '@/components/component-library/Modal';
import Text, { FONT_SIZE_160 } from '@/components/component-library/Text';
import ButtonCTA from '@/components/component-library/Button/CTA';
import { classes } from '@/helpers/styling';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';

const LOCAL_STORAGE_ONBOARDING_COMPLETE_KEY = 'flag-onboarding-complete';

const Onboarding = () => {
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const handleCloseOnboarding = useCallback(() => {
    setOnboardingModalOpen(false);
  }, []);

  useEffect(() => {
    const hasSeenOnboarding = getLocalStorageItem(LOCAL_STORAGE_ONBOARDING_COMPLETE_KEY) || false;

    if (!hasSeenOnboarding) {
      setLocalStorageItem({
        key: LOCAL_STORAGE_ONBOARDING_COMPLETE_KEY,
        value: true
      });

      setOnboardingModalOpen(true);
    }
  }, []);

  return (
    <Modal id='onboarding' onClick={handleCloseOnboarding} open={onboardingModalOpen}>
      <LayoutWrapperHorizontal className={styles.onboardingModalContent} fillHeight>
        <Text bold className={styles.title} size={FONT_SIZE_160}>
          A new way to discover music
        </Text>
        <div className={styles.tipSection}>
          <Tip
            header="Recommendations every day"
            Icon={ShowsIcon}
          />
          <Tip
            header="Hand-picked by our Curators"
            Icon={HandpickedIcon}
          />
          <Tip
            header="Start a new musical journey"
            Icon={LocationJourneyIcon}
          />
        </div>
        <ButtonCTA className={classes(styles.startListeningButton, styles.blue)} isRectangular onClick={handleCloseOnboarding}>
          Start Listening
        </ButtonCTA>
      </LayoutWrapperHorizontal>
    </Modal>
  );
};

export default Onboarding;
