import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { isShowNewlyReleased, sliceGenres } from '@/helpers/common';
import pageKeys from '@/helpers/routing/constants';
import {
  FONT_SIZE_140, FONT_SIZE_100, FONT_SIZE_060
} from '@/config/fontSizes';
import Text from '@/components/component-library/Text';
import GenreBadges from '@/components/component-library/GenreBadges';
import PlayPauseCTA from '@/components/component-library/Button/PlayPauseCTA';
import Link from '@/components/component-library/Link';
import useStory from '@/pages/discover/common/hooks/useStory';
import TextLoadingBlock from '@/components/component-library/Text/TextLoadingBlock';
import BadgeNew from '@/components/component-library/BadgeNew';
import useStories from '@/react-query/discover/hooks/useStories';

const SHOW_GENRE_BADGES = false;

const HeroStoryContent = ({ includeMoreInfo }) => {
  const {
    isPaused,
    isLoading,
    isFetching,
    playButtonIsLoading,
    handleClickStory,
    handleClickPlayStory,
    seasonNumberToPlay,
    episodeNumberToPlay,
    isCurrentlyPlayingShow,
    show,
    storyDescription
  } = useStory({ useRandomStoryHook: true });

  const { title: storiesTitle } = useStories({ isEnabled: true });

  const loading = isFetching || isLoading;

  const {
    title,
    genres,
    slug,
    release_date
  } = show || {};

  const isNew = isShowNewlyReleased({ releaseDate: release_date });

  const showHref = includeMoreInfo ? pageKeys.shows(slug) : undefined;

  return (
    <div className={ styles.heroStoryWrapper }>
      <div>
        { loading ? (
          <TextLoadingBlock size={ FONT_SIZE_100 } />
        ) : (
          <Text
            bold
            className={ styles.storyTitle }
            size={ FONT_SIZE_100 }
            useHeaderFont
          >
            { storiesTitle ?? 'Stories Title' }
            { ' ' }
            { !!title && (
              <Link className={ styles.showTitleLink } href={ showHref } onClick={ handleClickStory }>
                { title }
              </Link>
            ) }
            { isNew && (
              <BadgeNew
                className={ styles.newBadge }
                fontSize={ FONT_SIZE_060 }
                largeSize
              />
            ) }
          </Text>
        ) }
        { loading ? (
          <TextLoadingBlock className={ styles.storyDescription } lineCount={ 3 } size={ FONT_SIZE_140 } />
        ) : (
          <Text
            bold
            className={ styles.storyDescription }
            size={ FONT_SIZE_140 }
            useHeaderFont
          >
            { storyDescription }
          </Text>
        ) }
      </div>
      { SHOW_GENRE_BADGES && (
        <GenreBadges
          className={ styles.genres }
          enableLinks
          genres={ sliceGenres({
            genres,
            limit: 2
          }) }
          loading={ loading }
        />
      ) }
      <PlayPauseCTA
        className={ styles.buttons }
        disabled={ playButtonIsLoading }
        episodeNumber={ episodeNumberToPlay }
        isCurrentlyPlayingShow={ isCurrentlyPlayingShow }
        loading={ playButtonIsLoading }
        onClick={ handleClickPlayStory }
        paused={ isPaused }
        seasonNumber={ seasonNumberToPlay }
      />
    </div>
  );
};

HeroStoryContent.propTypes = { includeMoreInfo: PropTypes.bool };

HeroStoryContent.defaultProps = { includeMoreInfo: false };

export default HeroStoryContent;