import LogoIcon from '@/components/component-library/Icon/types/Logo';
import HeaderBarMobile from '@/components/app/Headers/HeaderBarMobile';
import { classes } from '@/helpers/styling';

const DiscoverMobileHeader = () => {
  return (
    <HeaderBarMobile
      center={ (
        <div className='logo-icon-wrapper-mobile'>
          <LogoIcon />
        </div>
      ) }
      className={ classes('mob__header-bar') }
    />
  );
};

export default DiscoverMobileHeader;