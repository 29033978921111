import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Text, { FONT_SIZE_070, FONT_SIZE_060 } from '@/components/component-library/Text';
import { classes } from '@/helpers/styling';

const Tip = ({
  header, description, Icon, className
}) => {
  return (
    <div className={ classes(styles.tipWrapper, className) }>
      <div className={ styles.tipIcon }>
        <Icon />
      </div>
      <div className={ styles.tipTextWrapper }>
        <Text bold className={ styles.header } size={ FONT_SIZE_070 }>{ header }</Text>
        <Text className={ styles.description } size={ FONT_SIZE_060 }>{ description }</Text>
      </div>
    </div>
  );
};

Tip.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  Icon: PropTypes.elementType.isRequired,
  className: PropTypes.string
};

Tip.defaultProps = {
  className: '',
  description: ''
};

export default Tip;