import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Logo = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 261.26 245.75'
  >
    <title>Keakie Logo</title>
    <g fill={ color }>
      <path d='M165.79,122.84,135.38,92.43h0L43,.06H0V245.62H43l92.37-92.38,92.5,92.5,30.41-30.41-92.5-92.5ZM43,184.8V60.88l62,62Z' />
      <rect
        height='43.01'
        transform='translate(37.76 168.68) rotate(-45)'
        width='66.62'
        x='189.19'
        y='17.26'
      />
    </g>
  </svg>
);

Logo.propTypes = { ...iconPropTypes };

Logo.defaultProps = { ...iconDefaultProps };

export default Logo;