import PropTypes from 'prop-types';
import Gradient, { GRADIENT_POSITION_TOP } from '@/components/app/Gradient';
import HeaderBar from '@/components/app/Headers/HeaderBar';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import { classes } from '@/helpers/styling';
import useShouldRenderForMobile from '@/hooks/layout/useShouldRenderForMobile';
import useAppFilter from '@/hooks/layout/useAppFilter';

// Mobile format header bar with three horizontal sections
const HeaderBarMobile = ({
  children, // If children are passed, then they will be rendered
  left, // A component to display in the left section
  center, // A component to display in the center section
  right, // A component to display in the right section
  className,
  forceShow, // Force the header to display even if not mobile mode
  gradientProps // Props to pass to the gradient component
}) => {
  const shouldRenderForMobile = useShouldRenderForMobile();
  const { appIsFiltered } = useAppFilter();
  
  const content = children ? (
    <div className='content'>
      { children }
    </div>
  ) : (
    <>
      <div className='left'>
        { left }
      </div>
      <div className='center'>
        { center }
      </div>
      <div className='right'>
        { right }
      </div>
    </>
  );
  
  return (shouldRenderForMobile || forceShow) ? (
    <HeaderBar
      className={ classes(className, 'header-mobile', { '--is-filtered': appIsFiltered }) }
    >
      <Gradient
        breadth='150%'
        position={ GRADIENT_POSITION_TOP }
        { ...gradientProps }
      />
      <LayoutWrapperHorizontal forceMobile>
        <div className='header-mobile-content'>
          { content }
        </div>
      </LayoutWrapperHorizontal>
    </HeaderBar>
  ) : null;
};

HeaderBarMobile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  left: PropTypes.any,
  center: PropTypes.any,
  right: PropTypes.any,
  forceShow: PropTypes.bool,
  gradientProps: PropTypes.object
};

HeaderBarMobile.defaultProps = {
  children: null,
  className: '',
  left: null,
  center: null,
  right: null,
  forceShow: false,
  gradientProps: {}
};

export default HeaderBarMobile;