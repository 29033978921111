import DiscoverPageDesktop from './desktop';
import DiscoverPageMobile from './mobile';
import { DiscoverPageProvider } from './common/contexts/page';
import { getServerSideProps as getServerSidePropsFunction } from './common/getServerSideProps';
import Meta from '@/components/app/Meta';
import Page from '@/components/app/Page';
import { createSDOrganisation, createSDSoftwareApplication } from '@/helpers/structured-data/common';
import useShouldRenderForDesktop from '@/hooks/layout/useShouldRenderForDesktop';
import useShouldRenderForMobile from '@/hooks/layout/useShouldRenderForMobile';
import { createDiscoverPageDescription, createDiscoverPageTitle } from '@/helpers/seo/discover';
import useDiscoverData from '@/react-query/discover/hooks/useDiscoverData';
import Onboarding from '@/components/app/Onboarding';
import { EXT_LINK_ANDROID_APP, EXT_LINK_IOS_APP } from '@/config/external-links';

const DISCOVER_PAGE_TITLE = createDiscoverPageTitle();
const DISCOVER_PAGE_DESCRIPTION = createDiscoverPageDescription();
const DISCOVER_PAGE_STRUCTURED_DATA = [
  {
    id: 'organisation',
    data: createSDOrganisation()
  },
  {
    id: 'android-app',
    data: createSDSoftwareApplication({
      url: EXT_LINK_ANDROID_APP,
      operatingSystem: 'ANDROID'
    })
  },
  {
    id: 'ios-app',
    data: createSDSoftwareApplication({
      url: EXT_LINK_IOS_APP,
      operatingSystem: 'IOS'
    })
  }
];

const Discover = () => {
  const {
    isError, notFound, refetch: handleClickRetry
  } = useDiscoverData();

  const shouldRenderForMobile = useShouldRenderForMobile();
  const shouldRenderForDesktop = useShouldRenderForDesktop();

  return (
    <Page
      error={ isError }
      meta={ (
        <Meta
          description={ DISCOVER_PAGE_DESCRIPTION }
          notFound={ notFound }
          structuredData={ DISCOVER_PAGE_STRUCTURED_DATA }
          title={ DISCOVER_PAGE_TITLE }
          useTitleRaw
        />
      ) }
      notFound={ notFound }
      onClickRetry={ handleClickRetry }
      pageId='discover'
    >
      <DiscoverPageProvider>
        <Onboarding />
        { shouldRenderForDesktop && <DiscoverPageDesktop /> }
        { shouldRenderForMobile && <DiscoverPageMobile /> }
      </DiscoverPageProvider>
    </Page>
  );
};

export const getServerSideProps = getServerSidePropsFunction;

export default Discover;