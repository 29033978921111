import isTouchDevice from 'is-touch-device';
import Story from '../Story/Story';
import styles from './styles.module.scss';
import { size } from '@/helpers/lodash';
import useStories from '@/react-query/discover/hooks/useStories';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import useInitialRender from '@/hooks/browser/useInitialRender';
import { classes } from '@/helpers/styling';

const ENABLE_SCROLL_SNAP = false;

const Stories = () => {
  const {
    stories,
    isLoading
  } = useStories();
  const { isFirstRender } = useInitialRender();
  const touchDevice = isTouchDevice();
  const disableScrollSnap = !isFirstRender && !touchDevice;

  return (
    <LayoutWrapperHorizontal className={ classes(styles.scrollableRow, { [styles.disableScrollSnap]: !ENABLE_SCROLL_SNAP || disableScrollSnap }) } disable isRow>
      {
        isLoading || !size(stories)
          ? [...Array(2)].map((_, i) => <Story key={ i } />)
          : stories.map(({ show: { id: showId } }) => <Story key={ showId } showId={ showId } />)
      }
    </LayoutWrapperHorizontal>
  );
};

export default Stories;