import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import HeroStoryContent from './HeroStoryContent';
import styles from './styles.module.scss';
import { size } from '@/helpers/lodash';
import ShowHeroDesktop from '@/components/app/ShowHeroDesktop/HeroShow';
import useStories from '@/react-query/discover/hooks/useStories';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import CarouselIndicators from '@/components/component-library/CarouselIndicators';
import { classes } from '@/helpers/styling';
import { SMALL } from '@/config/assets';
import SquareImage from '@/components/component-library/Image/KeakieImageSquare';
import useRandomStory from '@/react-query/discover/hooks/useRandomStory';

const ENABLE_MULTIPLE_STORIES = false;
const ENABLE_CAROUSEL = false;
const ENABLE_IMAGE_INDICATORS = false;
const ENABLE_SLIDING = false;

const CustomIndicator = ({ assets, ...props }) => {
  return (
    <div { ...props }>
      <SquareImage
        assetSize={ SMALL }
        assetsObj={ assets }
        hasBorderRadius
      />
    </div>
  );
};

CustomIndicator.propTypes = { assets: PropTypes.object.isRequired };

const HeroStories = () => {
  const {
    stories,
    isFetching: isFetchingMultipleStories
  } = useStories({ isEnabled: ENABLE_MULTIPLE_STORIES });
  const { story, isFetching: isFetchingSingleStory } = useRandomStory({ isEnabled: !ENABLE_MULTIPLE_STORIES });
  const [selectedId, setSelectedId] = useState(undefined);
  const [shouldTransitionHeroSlider, setShouldTransitionHeroSlider] = useState(false);
  const carouselIds = (stories || []).map((s) => s?.show?.id);

  const isFetching = (ENABLE_MULTIPLE_STORIES ? isFetchingMultipleStories : isFetchingSingleStory);

  const selectedStory = useMemo(() => (
    ENABLE_MULTIPLE_STORIES
      ? (stories || []).find((s) => s?.show?.id === selectedId) || (stories || [])[0]
      : story
  ), [selectedId, stories, story]);
  const selectedShowIndex = useMemo(() => (stories || []).findIndex((s) => s?.show?.id === selectedId) || 0, [selectedId, stories]);
  const selectedShow = selectedStory?.show;
  const selectedShowFormatted = useMemo(() => ({
    ...selectedShow,
    description: selectedStory?.description
  }), [selectedStory, selectedShow]);
  const selectedShowId = selectedShow?.id;

  useEffect(() => {
    if (selectedId !== selectedShowId) {
      setSelectedId(selectedShowId);
    }
  }, [selectedId, selectedShowId]);

  const handleSelectCarouselId = useCallback((id) => {
    setShouldTransitionHeroSlider(true);
    setSelectedId(id);
  }, []);

  return (
    <div className={ styles.carouselIndicatorsAndHeroWrapper }>
      { (ENABLE_SLIDING && ENABLE_MULTIPLE_STORIES)
        ? (
          <div
            className={ classes(styles.heroSlider, { [styles.isTransitioned]: shouldTransitionHeroSlider }) } style={ { left: `-${ selectedShowIndex * 100 }vw` } }
          >
            { (stories || []).map((s, i) => {
              if (!s?.show?.id) {
                return null;
              }
          
              const isSelectedShow = s?.show?.id === selectedId;

              return (
                <ShowHeroDesktop
                  // enableLeftGradient
                  isLoading={ isFetching }
                  key={ s?.show?.id }
                  show={ {
                    ...s?.show,
                    description: s?.description
                  } }
                  style={ ENABLE_SLIDING ? {
                    left: `${ i * 100 }vw`,
                    transition: 'opacity 1s ease-in-out',
                    zIndex: -i,
                    opacity: isSelectedShow ? 1 : 0
                  } : undefined }
                >
                  <HeroStoryContent includeMoreInfo />
                </ShowHeroDesktop>
              );
            }) }
          </div>
        ) : (
          <ShowHeroDesktop
            enableLeftGradient
            isLoading={ isFetching }
            key={ selectedId }
            show={ selectedShowFormatted }
          >
            <HeroStoryContent includeMoreInfo />
          </ShowHeroDesktop>
        ) }
      { ENABLE_MULTIPLE_STORIES && ENABLE_CAROUSEL && !!size(stories) && (
        <div className={ styles.carouselIndicatorsWrapper }>
          <LayoutWrapperHorizontal className={ styles.carouselIndicatorsAdjusted } isRow>
            { !!selectedId && false && (
              <CarouselIndicators
                Component={ ENABLE_IMAGE_INDICATORS ? CustomIndicator : undefined }
                getIndicatorProps={ ENABLE_IMAGE_INDICATORS ? (id) => ({ assets: (stories || []).find((s) => s?.show?.id === id)?.show?.assets }) : undefined }
                ids={ carouselIds }
                indicatorClassName={ ENABLE_IMAGE_INDICATORS ? styles.customIndicator : undefined }
                onSelectId={ handleSelectCarouselId }
                selectedId={ selectedId }
              />
            ) }
          </LayoutWrapperHorizontal>
        </div>
      ) }
    </div>
  );
};

export default HeroStories;