import { useCallback, useMemo } from 'react';
import useQuery from '@/react-query/common/hooks/useQuery';
import useDiscoverData from '@/react-query/discover/hooks/useDiscoverData';
import { callApi } from '@/helpers/api';
import { QK_DISCOVER_ROW_SHOWS } from '@/config/query-keys';
import { formatChannel } from '@/helpers/common';

const useDiscoverRow = ({
  id = null,
  hero = false,
  enabled = true
} = {}) => {
  const {
    isSuccess: discoverSuccess,
    rowsObject,
    heroRowId
  } = useDiscoverData();

  const rowId = hero ? heroRowId : id;
  const row = rowsObject[rowId] || {};
  const rowEndpoint = row.endpoint;

  const fetchDiscoverRow = useCallback(() => callApi({
    url: rowEndpoint,
    id: rowId,
    queryParams: { l: 25 }
  }), [rowId, rowEndpoint]);

  const results = useQuery({
    queryKey: [QK_DISCOVER_ROW_SHOWS, rowId],
    queryFn: fetchDiscoverRow,
    enabled: !!(discoverSuccess && enabled && rowEndpoint && rowId),
    staleTime: 1000 * 60 * 30 // 30 minutes
  });

  const { data } = results;

  const {
    json,
    notFound,
    status: statusCode
  } = data || {};

  const showsArray = useMemo(
    () => (
      hero
        ? [json]
        : (json?.nodes || [])
    )
      .filter(_ => _)
      .map((show) => formatChannel(show)),
    [hero, json]
  );

  return {
    ...results,
    notFound,
    statusCode,
    rowId,
    row,
    showsArray,
    firstShow: showsArray[0] || {}
  };
};

export default useDiscoverRow;