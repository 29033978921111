import PropTypes from 'prop-types';
import useStory from '../../../../common/hooks/useStory';
import styles from './styles.module.scss';
import PlayPauseCTA from '@/components/component-library/Button/PlayPauseCTA';
import InfoIcon from '@/components/component-library/Icon/types/Info';
import ButtonWrapper from '@/components/component-library/Button/Wrapper';
import { classes } from '@/helpers/styling';
import { truthyOrZero } from '@/helpers/math';
import CONFIG from '@/config/global';
import useWindowSize from '@/hooks/browser/useWindowSize';

const ActionButtons = ({ showId, className }) => {
  const { width } = useWindowSize({ debounceTime: CONFIG.layoutShiftDebounceMs });
  const isSmall = (width < 440);

  const {
    isFetching,
    isLoading,
    episodeNumberToPlay,
    hidePlayButton,
    isCurrentlyPlayingShow,
    isPaused,
    handleClickPlayStory,
    seasonNumberToPlay
  } = useStory({ id: showId });

  const loading = isFetching || isLoading;

  const playButtonHasLongText = isCurrentlyPlayingShow
    && truthyOrZero(episodeNumberToPlay);

  return (
    <div className={ classes(styles.actionButtons, className) }>
      { !hidePlayButton && (
        <PlayPauseCTA
          className={ styles.playPauseButton }
          disabled={ loading }
          disableEpisodeText={ (isSmall && playButtonHasLongText) || `${ seasonNumberToPlay }${ episodeNumberToPlay }`.length > 5 }
          episodeNumber={ episodeNumberToPlay }
          isCurrentlyPlayingShow={ isCurrentlyPlayingShow }
          loading={ loading }
          onClick={ handleClickPlayStory }
          paused={ isPaused }
          seasonNumber={ seasonNumberToPlay }
        />
      ) }
      { !loading && (
        <ButtonWrapper
          className={ styles.infoButton }
        >
          <div className={ styles.infoIcon }>
            <InfoIcon />
          </div>
        </ButtonWrapper>
      ) }
    </div>
  );
};

ActionButtons.propTypes = {
  showId: PropTypes.string.isRequired,
  className: PropTypes.string
};

ActionButtons.defaultProps = { className: '' };

export default ActionButtons;