import PropTypes from 'prop-types';
import useStory from '../../../common/hooks/useStory';
import ActionButtons from './ActionButtons';
import OuterTile from './OuterTile';
import styles from './styles.module.scss';
import Details from './Details';
import { XLARGE } from '@/config/assets';
import { classes } from '@/helpers/styling';
import SquareImage from '@/components/component-library/Image/KeakieImageSquare';

const Story = ({ showId }) => {
  const {
    isLoading,
    isFetching,
    altText,
    showHref,
    assets,
    handleClickStory,
    showId: fetchedShowId,
    storyDescription
  } = useStory({ id: showId });

  const loading = isFetching || isLoading;

  return (
    <OuterTile
      assets={ assets }
      href={ showHref }
      isLoading={ loading }
      onClick={ fetchedShowId ? handleClickStory : undefined }
    >
      <SquareImage
        alt={ altText }
        assetSize={ XLARGE }
        assetsObj={ assets }
        className={ classes(styles.squareStoryImage, { [styles.squareImageLoading]: loading }) }
        disableBorderRadius
        forceHighDensity
        loading={ loading }
      />
      <Details
        description={ storyDescription }
        loading={ loading }
      />
      <div className={ styles.actionButtonsWrapper }>
        <ActionButtons showId={ showId } />
      </div>
    </OuterTile>
  );
};

Story.propTypes = { showId: PropTypes.string };

Story.defaultProps = { showId: '' };

export default Story;