import { Fragment } from 'react';
import PropTypes from 'prop-types';
import ConnectedDiscoverRow from './ConnectedDiscoverRow';
import { size } from '@/helpers/lodash';
import useDiscoverData from '@/react-query/discover/hooks/useDiscoverData';
import { classes } from '@/helpers/styling';

const DiscoverRows = ({ className }) => {
  const {
    heroRowId, rowIds, isFetching: isLoading
  } = useDiscoverData();

  // ------------------- ROWS -------------------
  // Display the rows (excluding the hero row)
  // Display 6 loading rows if loading or there are no rows
  const rowsArray = (!isLoading && size(rowIds))
    ? rowIds.filter((rowId) => (rowId !== heroRowId))
    : [...Array(6)];

  // Construct the row components
  const rowsToDisplay = rowsArray.map((rowId) => (
    <ConnectedDiscoverRow
      id={ rowId }
      isLoading={ !rowId }
      key={ rowId }
    />
  ));

  return (
    <div className={ classes('discover-rows-wrapper', className) }>
      {
        rowsToDisplay.map((r, index) => (
          <Fragment key={ index }>
            { r }
          </Fragment>
        ))
      }
    </div>
  );
};

DiscoverRows.propTypes = { className: PropTypes.string };

DiscoverRows.defaultProps = { className: '' };

// ------ Connect with the store and inject required state as props ------
export default DiscoverRows;