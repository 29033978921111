import { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import Overlay from '@/components/app/Overlay';
import useDelayedMount from '@/hooks/styling/useDelayedMount';
import { classes } from '@/helpers/styling';
import useModalState from '@/hooks/state/useModalState';

const Modal = ({
  open,
  children,
  onClick,
  onBlur,
  id,
  zIndex
}) => {
  const {
    openModal, closeModal, openModalZIndexes
  } = useModalState();
  const { shouldRender, transitionComplete } = useDelayedMount({
    shouldMount: open,
    delayTime: 350
  });

  const isUnderAnotherModal = openModalZIndexes[0]?.id !== id;

  useEffect(() => {
    if (open) {
      openModal({
        id,
        zIndex
      });
    }

    return () => {
      if (open) {
        closeModal({
          id,
          zIndex
        });
      }
    };
  }, [open, id, zIndex, openModal, closeModal]);

  return shouldRender && (
    <Overlay
      backgroundClassName={ classes(styles.overlayBackground, { [styles.isUnderAnotherModal]: open && isUnderAnotherModal }) }
      className={ classes(styles.overlayContent, { [styles.isUnderAnotherModal]: open && isUnderAnotherModal }) }
      commonClassName={ classes(styles.overlayCommon, { [styles.isOpen]: transitionComplete && open }) }
      id={ id }
      onBlur={ onBlur }
      onClick={ onClick }
      zIndex={ zIndex }
    >
      { (typeof children === 'function') ? children({ open }) : children }
    </Overlay>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  zIndex: PropTypes.number
};

Modal.defaultProps = {
  open: false,
  onClick: undefined,
  onBlur: undefined,
  id: '',
  zIndex: 110
};

export default Modal;