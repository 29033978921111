import {
  GA_ACT_PLAYED_STORY,
  GA_ACT_CLICKED_STORY
} from '@/config/events-ga/actions';
import { GA_CAT_FEATURE_ENGAGEMENT } from '@/config/events-ga/categories';
import {
  GA_DIM_SHOW_ID,
  GA_DIM_SHOW_TITLE
} from '@/config/events-ga/dimensions';

export const GAplayedStory = ({
  showId,
  showTitle
} = {}) => ({
  eventCategory: GA_CAT_FEATURE_ENGAGEMENT,
  eventAction: GA_ACT_PLAYED_STORY,
  dimensions: {
    [GA_DIM_SHOW_ID]: showId,
    [GA_DIM_SHOW_TITLE]: showTitle
  }
});

export const GAclickedStory = ({
  showId,
  showTitle
} = {}) => ({
  eventCategory: GA_CAT_FEATURE_ENGAGEMENT,
  eventAction: GA_ACT_CLICKED_STORY,
  dimensions: {
    [GA_DIM_SHOW_ID]: showId,
    [GA_DIM_SHOW_TITLE]: showTitle
  }
});