import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Shows = ({ className, color }) => (
  <svg
    className={ className }
    viewBox='0 0 41 37'
    { ...iconCommonProps }
  >
    <path
      d='M13.667 4.556A4.557 4.557 0 0 1 18.222 0h18.222A4.558 4.558 0 0 1 41 4.556v27.333a4.56 4.56 0 0 1-4.556 4.555H18.222a4.56 4.56 0 0 1-4.555-4.555V4.556Zm1.139 0v27.333a3.418 3.418 0 0 0 3.416 3.417h18.222a3.418 3.418 0 0 0 3.417-3.417V4.556a3.417 3.417 0 0 0-3.417-3.417H18.222a3.417 3.417 0 0 0-3.416 3.417Zm-7.973-.57a.57.57 0 1 1 1.14 0v28.472a.57.57 0 0 1-1.139 0V3.986ZM0 7.403a.57.57 0 1 1 1.139 0v21.639a.57.57 0 0 1-1.139 0V7.402Z'
      fill={ color }
    />
  </svg>
);

Shows.propTypes = { ...iconPropTypes };

Shows.defaultProps = { ...iconDefaultProps };

export default Shows;