import PropTypes from 'prop-types';
import Badge from '../Badge';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const BadgeNew = ({ className, ...props }) => {
  return (
    <Badge
      className={ classes(styles.newBadge, className) }
      { ...props }
      title='NEW'
    />
  );
};

BadgeNew.propTypes = { className: PropTypes.string };

BadgeNew.defaultProps = { className: '' };

export default BadgeNew;