import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useState
} from 'react';
import styles from './styles.module.scss';
import { SMALL } from '@/config/assets';
import SlateImage from '@/components/component-library/Image/KeakieImageSlate';
import { classes } from '@/helpers/styling';
import Link from '@/components/component-library/Link';
import TileGrain from '@/components/app/TileGrain';

const Tile = ({
  children,
  assets,
  onClick,
  href,
  isLoading
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasBeenPressed, setHasBeenPressed] = useState(false);

  useEffect(() => setHasLoaded(!isLoading), [isLoading]);

  const handleClick = useCallback((...args) => {
    setHasBeenPressed(true);

    if (onClick) {
      onClick(...args);
    }
  }, [onClick]);

  return (
    <Link
      className={ classes(styles.tile, {
        [styles.isLoading]: !assets,
        [styles.hasBeenPressed]: hasBeenPressed
      }) }
      href={ href }
      onClick={ handleClick }
    >
      <SlateImage
        assetSize={ SMALL }
        assetsObj={ assets }
        className={ styles.tileBackground }
        disableBorderRadius
        loading={ isLoading || !assets }
      />
      <TileGrain isLoaded={ hasLoaded } />
      <div className={ styles.tileContent }>
        { children }
      </div>
    </Link>
  );
};

Tile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  assets: PropTypes.object,
  onClick: PropTypes.func,
  href: PropTypes.string,
  isLoading: PropTypes.bool
};

Tile.defaultProps = {
  assets: null,
  onClick: undefined,
  href: '',
  isLoading: false
};

export default Tile;