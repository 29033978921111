import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const LocationJourney = ({ className, color }) => (
  <svg
    className={ className }
    viewBox='0 0 42 42'
    { ...iconCommonProps }
  >
    <path
      d='M31.5 7.875a2.624 2.624 0 0 1 2.625-2.625 2.624 2.624 0 0 1 2.625 2.625 2.622 2.622 0 0 1-2.625 2.625A2.622 2.622 0 0 1 31.5 7.875Zm2.625 1.313c.722 0 1.313-.591 1.313-1.313 0-.725-.591-1.313-1.313-1.313s-1.313.588-1.313 1.313c0 .722.591 1.313 1.313 1.313Zm0 11.812h-7.219a4.597 4.597 0 0 0-4.593 4.594 4.597 4.597 0 0 0 4.593 4.593h9.188A5.903 5.903 0 0 1 42 36.094 5.902 5.902 0 0 1 36.094 42H13.125a.658.658 0 0 1-.656-.656c0-.361.295-.657.656-.657h22.969a4.597 4.597 0 0 0 4.593-4.593 4.597 4.597 0 0 0-4.593-4.594h-9.188A5.902 5.902 0 0 1 21 25.594a5.903 5.903 0 0 1 5.906-5.907h5.915c-2.141-2.46-6.571-7.875-6.571-11.812C26.25 3.525 29.703 0 34.125 0A7.876 7.876 0 0 1 42 7.875c0 4.143-4.906 9.918-6.973 12.173-.55.6-.902.952-.902.952Zm5.471-9.45c.698-1.395 1.092-2.658 1.092-3.675a6.562 6.562 0 0 0-6.563-6.563 6.562 6.562 0 0 0-6.563 6.563c0 1.017.394 2.28 1.091 3.675.69 1.386 1.625 2.79 2.585 4.069a46.996 46.996 0 0 0 2.633 3.19c.016.1.172.19.254.28.008-.09.164-.18.254-.28a46.996 46.996 0 0 0 2.634-3.19c.96-1.28 1.894-2.683 2.584-4.069ZM10.5 28.875A2.622 2.622 0 0 1 7.875 31.5a2.624 2.624 0 0 1-2.625-2.625 2.624 2.624 0 0 1 2.625-2.625 2.622 2.622 0 0 1 2.625 2.625Zm-2.625-1.313c-.725 0-1.313.591-1.313 1.313s.588 1.313 1.313 1.313c.722 0 1.313-.591 1.313-1.313s-.591-1.313-1.313-1.313Zm0 14.438s-.35-.353-.903-.952C4.903 38.793 0 33.018 0 28.875 0 24.453 3.526 21 7.875 21c4.348 0 7.875 3.453 7.875 7.875 0 4.143-4.905 9.918-6.973 12.173-.55.6-.902.952-.902.952Zm5.472-9.45c.697-1.394 1.09-2.658 1.09-3.675a6.56 6.56 0 0 0-6.562-6.563 6.562 6.562 0 0 0-6.563 6.563c0 1.017.391 2.28 1.092 3.675.691 1.386 1.626 2.79 2.583 4.069a48.536 48.536 0 0 0 2.633 3.19l.255.28c.081-.09.166-.18.255-.28.721-.803 1.68-1.919 2.632-3.19.96-1.28 1.895-2.683 2.585-4.069Z'
      fill={ color }
    />
  </svg>
);

LocationJourney.propTypes = { ...iconPropTypes };

LocationJourney.defaultProps = { ...iconDefaultProps };

export default LocationJourney;