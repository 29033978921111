import { useCallback, useMemo } from 'react';
import useQuery from '@/react-query/common/hooks/useQuery';
import { QK_RANDOM_STORY } from '@/config/query-keys';
import { formatChannel } from '@/helpers/common';
import getRandomStory from '@/helpers/api/discover/getRandomStory';

const useRandomStory = ({ enabled = true } = {}) => {
  const fetchRandomStory = useCallback(() => getRandomStory(), []);

  const results = useQuery({
    queryKey: [QK_RANDOM_STORY],
    queryFn: fetchRandomStory,
    enabled,
    staleTime: 1000 * 60 * 2 // 2 minutes
  });

  const { data } = results;

  const {
    json: story,
    notFound,
    status: statusCode
  } = data || {};

  const { show, description } = story || {};

  const descriptionFormatted = (description || '').trim();
  const storyFormatted = useMemo(
    () => ({
      ...story,
      show: formatChannel(show),
      description: (description || '').trim()
    }),
    [story, show, description]
  );

  return {
    ...results,
    notFound,
    statusCode,
    story: storyFormatted,
    description: descriptionFormatted
  };
};

export default useRandomStory;